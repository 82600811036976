.container,
.content_box {
  height: 100%;
}
.content {
  height: calc(100% - 0.2rem);
  margin: 0.2rem 0;
  margin-bottom: 0;
  font-family: PingFangSC-Regular;
}
.tabBox {
  height: 100%;
  border-top-left-radius: 0.32rem;
  border-top-right-radius: 0.32rem;
}
.content-member {
  padding: 0.4rem 0;
  text-align: center;
}
.content-member .zhiTitle {
  font-size: 0.24rem;
}
.content-member .zhiCent {
  font-size: 0.24rem;
  margin-bottom: 0.15rem;
  margin-top: 0.15rem;
  color: #666666;
  display: flex;
  align-items: center;
}
.content-member .zhiCent .state img {
  width: 0.24rem;
  display: block;
  margin: 0 auto;
}
.content-member .zhiCent .active {
  color: #2E62FF;
}
.content-member .zhiCent .unactive {
  color: #6F7989;
}
