













































































.container,
.content_box{
	height: 100%;
}
.content {
	height: calc(100% - 0.2rem);
	margin: 0.2rem 0;
	margin-bottom: 0;
	font-family: PingFangSC-Regular;
}
.tabBox {
	height: 100%;
	border-top-left-radius: 0.32rem;
	border-top-right-radius: 0.32rem;
}

.content-member {
	padding: 0.4rem 0;
	text-align: center;
	.zhiTitle {
		font-size: 0.24rem;
	}

	.zhiCent {
		font-size: 0.24rem;
		margin-bottom: 0.15rem;
		margin-top: 0.15rem;
		color: #666666;
		display: flex;
		align-items: center;
		.state img {
			width: 0.24rem;
			display: block;
			margin: 0 auto;
		}
		.active {
			color: #2E62FF;
		}
		.unactive {
			color: #6F7989;
		}
	}
	
	.left {
		// text-align: left;
	}
}

@font-size-sm: 14px;@font-size-md: 16px;@font-size-lg: 18px;@goods-action-button-danger-color: #7232dd;@goods-action-button-warning-color: #3eaf7c;